import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import {
  updatePersonAdmin,
  updatePersonAdminNorkart,
  deleteAdKobling,
} from 'api/personApi';
import {
  getApneForSaksbehandler,
  updateSaksbehandlerForApne,
} from 'api/avvikApi';
import { getAntallSidemannsoppgaver } from 'api/smkApi.js';
import { debugFactory } from 'utils';
import DialogStore from 'lib/DialogStore';
import EndreSaksbehandlerAvvik from './EndreSaksbehandlerAvvik.jsx';
import { contextInnstillingerPerson, getOrganisasjonData } from 'context/contextInnstillinger.js';
import OkDialog from 'components/Dialogs/OkDialog.jsx';

const debug = debugFactory('nk:EndreBrukerdetaljer');
let aktivOppr = false;

const modalDialogs = {
  EndreSaksbehandlerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EndreSaksbehandlerAvvik
      isOpen={isOpen}
      tittel="Ny bruker"
      onClose={onClose}
      oppdater={owner.saksbehandlerOppdatert}
      personer={dialogData.personer}
      person={dialogData.person}
    />
  ),
  SidemannDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Fjern sideperson'}
        melding={'Sideperson kan ikke fjernes da bruker er tilknyttet oppgaver som sideperson. Disse må avsluttes før bruker fjernes som sideperson.'}
        onOk={onClose}
      />
    );
  },
};

class EndreBrukerdetaljer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      person: props.person,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.onPersonUpdated = this.onPersonUpdated.bind(this);
    aktivOppr = props.person.aktiv;
  }

  componentDidMount() {
    console.log(this.props.personer);
    getOrganisasjonData(this.gotOrg);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      this.setState({ person: nextProps.person });
    }
  }

  gotOrg = (org) => {
    this.setState({ org: org.data });
    console.log(org.data);
  };

  onPersonUpdated(err, result) {
    if (!err) {
      this.setState({ person: null });
      this.props.oppdater(true);
    } else {
      alert(err.respons);
    }
  }

  saksbehandlerOppdatert = () => {
    console.log('saksbehandlerOppdatert');
    contextInnstillingerPerson._currentValue.norkartAdmin
      ? updatePersonAdminNorkart(
          this.state.person.id,
          this.state.person,
          this.onPersonUpdated
        )
      : updatePersonAdmin(
          this.state.person.id,
          this.state.person,
          this.onPersonUpdated
        );
  };

  onOk = () => {
    console.log('ok');
    if (!this.state.person.aktiv && aktivOppr) {
      getApneForSaksbehandler(this.state.person.id, this.sjekkAvvikDone);
    } else {
      contextInnstillingerPerson._currentValue.norkartAdmin
        ? updatePersonAdminNorkart(
            this.state.person.id,
            this.state.person,
            this.onPersonUpdated
          )
        : updatePersonAdmin(
            this.state.person.id,
            this.state.person,
            this.onPersonUpdated
          );
    }
  };

  sjekkAvvikDone = (err, res) => {
    console.log(res);
    if (res.antallAvvik > 0 || res.antallAnmerkning > 0) {
      console.log('open');
      this.dialogs.setOpenDialog('EndreSaksbehandlerDialog', {
        personer: this.props.personer, person: this.state.person
      });
    } else {
      this.saksbehandlerOppdatert();
    }
  };

  sidemannChange = () => {
    if (this.state.person.sidemannkontroll) {
      getAntallSidemannsoppgaver(this.state.person.id, this.sidemannoppgaverChecked);
    } else {
      this.setState({
        person: {
          ...this.state.person,
          sidemannkontroll: !this.state.person.sidemannkontroll,
          },
        });
    }
  }

  sidemannoppgaverChecked = (err, res) => {
    if (res > 0) {
      this.dialogs.setOpenDialog('SidemannDialog');
    } else {
      this.setState({
        person: {
          ...this.state.person,
          sidemannkontroll: !this.state.person.sidemannkontroll,
          },
        });
    }
  }

  render() {
    if (!this.state.person || !this.state.org) {
      return <div />;
    } else {
      debug(this.state.person);
    }
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <button
              className="close"
              onClick={() => {
                this.setState({ person: null });
                this.props.onClose();
              }}
              type="button"
            >
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Oppdater detaljer om bruker</h4>
          </div>
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="form">
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Aktiv</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        checked={this.state.person.aktiv}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              aktiv: !this.state.person.aktiv,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Navn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        autoFocus
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.person.navn}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              navn: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Tittel</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.person.tittel}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              tittel: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Telefonnummer</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        value={this.state.person.telefon}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              telefon: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Mobilnummer</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        value={this.state.person.mobil}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              mobil: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Epost</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: ola.nordmann@gmail.com"
                        maxLength="60"
                        className="form-control"
                        type="email"
                        value={this.state.person.epost}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              epost: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Norkart bruker</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        <input
                          className="form-check-input"
                          style={{ width: '20px', height: '20px' }}
                          type="checkbox"
                          readOnly={true}
                          checked={this.state.person.norkartBruker}
                        />
                      </div>
                    </div>
                  )}

                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Norkart administrator</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        <input
                          className="form-check-input"
                          style={{ width: '20px', height: '20px' }}
                          type="checkbox"
                          checked={this.state.person.norkartAdmin}
                          onChange={(e) => {
                            this.setState({
                              person: {
                                ...this.state.person,
                                norkartAdmin: !this.state.person.norkartAdmin,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  { contextInnstillingerPerson._currentValue.organisasjonAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Administrator</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        <input
                          className="form-check-input"
                          style={{ width: '20px', height: '20px' }}
                          type="checkbox"
                          checked={this.state.person.organisasjonAdmin}
                          onChange={(e) => {
                            this.setState({
                              person: {
                                ...this.state.person,
                                organisasjonAdmin: !this.state.person
                                  .organisasjonAdmin,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Pilotbruker</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        <input
                          className="form-check-input"
                          style={{ width: '20px', height: '20px' }}
                          type="checkbox"
                          checked={this.state.person.pilotBruker}
                          onChange={(e) => {
                            this.setState({
                              person: {
                                ...this.state.person,
                                pilotBruker: !this.state.person.pilotBruker,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  { contextInnstillingerPerson._currentValue.organisasjonAdmin && this.state.org.dokumentkontroll && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Sideperson</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        <input
                          className="form-check-input"
                          style={{ width: '20px', height: '20px' }}
                          type="checkbox"
                          checked={this.state.person.sidemannkontroll}
                          onChange={(e) => {
                            this.sidemannChange();
                            
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <hr />
                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Har kobling til AD</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        {this.state.person.adKobling ? 'Ja' : 'Nei'}
                      </div>
                    </div>
                  )}
                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>AD-navn</label>
                      </div>
                      <div className="col-sm-8 form-check ">
                        {this.state.person.adNavn}
                      </div>
                    </div>
                  )}
                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>AD-kobling </label>
                      </div>
                      <div className="col-sm-8">
                        <button
                          className="btn"
                          onClick={() => deleteAdKobling(this.state.person.id)}
                        >
                          Fjern AD-kobling
                        </button>
                      </div>
                    </div>
                  )}
                  { contextInnstillingerPerson._currentValue.norkartAdmin && (
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Organisasjon </label>
                      </div>
                      <div className="col-sm-8 ">
                        {this.state.person.orgNavn}
                      </div>
                    </div>
                  )}
                  <hr />

                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Gjennomføring åpner standard kart først</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        checked={this.state.person.visningKartStandard}
                        onChange={(e) => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              visningKartStandard: !this.state.person
                                .visningKartStandard,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary pull-right" onClick={this.onOk}>
              OK
            </button>
          </div>
          {this.dialogs.renderOpenDialog()}
        </div>
      </NKModal>
    );
  }
}
export default EndreBrukerdetaljer;
