import React from 'react';
import _ from 'lodash';
import L from 'leaflet';
import moment from 'moment';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:ToolPit');

const makeDatoExtra = (d) => {
  if (d) {
    return moment(d).format('DD.MM.YYYY');
  } else {
    return 'Dato ukjent';
  }
};

const getAdresse = (obj) => {
  if (obj.adresse) {
    return (
      '<tr><td colSpan="3"><font color="#51a026" size="2"><b>' +
      obj.adresse +
      '</b></font></td></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getEiendomKommune = (obj) => {
  if (obj.eiendom) {
    return (
      '<tr font color="#2e2d30" size="1"><td>' +
      _.get(obj, 'eiendom') +
      '</td><td>' +
      _.get(obj, 'kommune') +
      '</td></font></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getEiendom = (obj) => {
  if (obj.eiendom) {
    return (
      '<tr><td colSpan="3"><font color="#2e2d30" size="1">Eiendom: ' +
      obj.eiendom +
      '</font></td></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getKommune = (obj) => {
  if (obj.kommune) {
    return (
      '<tr><td colSpan="3"><font color="#2e2d30" size="1">Kommune: ' +
      obj.kommune +
      '</font></td></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getRisiko = (obj) => {
 
  let overstyrt = '';
  if (_.get(obj, 'skybrannData.bruksenhet.risikoverdiOverstyr')) {
    overstyrt = '(ov.)';
  }
  if (!obj.risiko) {
    obj.risiko = 0;
  }
  if (obj.risiko) {
    if (obj.risiko === ''){
      obj.risiko = 0;
    }
    if (obj.risiko !== '' ) {  //  if (obj.risiko !== '' && obj.risiko > 0) {
      return (
        '<tr><td colSpan="3"><font color="#2e2d30">Risiko enhet: ' +
        obj.risiko +overstyrt +
        '</font></td><td>'
      );
    }
  } else {
    return '<tr></tr>';
  }
};

const getRisikoFeiing = (obj) => {

  
  if (obj.source.skybrannData) {
    if (obj.source.skybrannData.royklop) {
      if (obj.source.skybrannData.royklop.length) {
        let s = '';
       
        for (let i = 0; i < obj.source.skybrannData.royklop.length; i++) {
          let overstyrt = '';
          let r = obj.source.skybrannData.royklop[i];
          let tall = 0;
          if (r.risikoverdi !== ''){
            tall =r.risikoverdi;
          }    
          s = s + tall;;

           if (r.risikoverdiOverstyr) {
              overstyrt = '(ov.)';
            };
            s = s + overstyrt + ', ';
          }
          if (s !== ''){
            s = s.slice(0, -2);
            s = 'Risiko røykløp: ' +s;
          }
          return (
            '<tr><td colSpan="3"><font color="#2e2d30">' + s + '</font></td><td>'
          );
        }
       
      }
    }
  
  return '';
};

export const getNesteForEnhet = (obj, parent) => {
  if (parent === 'Planlegging') {
    let rl = '';
    if (obj.datoTiltakRoyklop) {
      rl =
        '<tr><td colSpan="3"><font color="#2e2d30" >' +
        '<tr><td colSpan="3">&nbsp;</td></tr>' +
        '<tr><td colSpan="3"><font color="#2e2d30" >Neste tiltak på røykløp:</font></td></tr>' +
        '<tr><td colSpan="3">' +
        obj.datoTiltakRoyklop +
        '</td></tr>' +
        '</font></td></tr>';
    }
    if (obj.datoTiltakEnhet || obj.datoTiltakRoyklop) {
      return (
        '<tr><td colSpan="3"><font color="#2e2d30" ><b>Neste:</b></font></td></tr>' +
        '<tr><td colSpan="3"><font color="#2e2d30" ><b>' +
        obj.datoTiltakEnhet +
        '</b></font></td></tr>' +
        rl
      );
    } else {
      return '<tr></tr>';
    }
  } else {
    //Ikke bold dersom fra SoekEnheter
    if (obj.datoTiltakEnhet || obj.datoTiltakRoyklop) {
      return (
        '<tr><td colSpan="3"><font color="#2e2d30" ><b>Neste:</b></font></td></tr>' +
        '<tr><td colSpan="3"><font color="#2e2d30" >' +
        obj.datoTiltakEnhet +
        '</font></td></tr>' +
        '<tr><td colSpan="3"><font color="#2e2d30" >' +
        obj.datoTiltakRoyklop +
        '</font></td></tr>'
      );
    } else {
      return '<tr></tr>';
    }
  }
};

const getNesteForRoyklop = (obj) => {
  if (!obj.royklopId) {
    return '<tr></tr>';
  }
  let rId = obj.royklopId;
  let tiltakListe = _.get(obj, 'source.skybrannData.royklopTiltakType');
  let royklopListe = _.get(obj, 'source.skybrannData.royklop');

  let type = '';
  let plass = '';
  if (royklopListe) {
    for (let y = 0; y < royklopListe.length; y++) {
      const r = royklopListe[y];
      if (r.id === rId.toString()) {
        //zijn ze er?  plassering, type
        if (r.type) {
          type = 'Type: ' + r.type;
        } else {
          type = 'Type: ukjent';
        }
        if (r.plassering) {
          plass = '(Plass: ' + r.plassering + ')';
        } else {
          plass = '(Plass: ukjent)';
        }
      }
    }
  }

  let neste = [];
  let liste = [];
  let denne = '';
  let andre = '';

  if (tiltakListe) {
    tiltakListe = _.orderBy(tiltakListe, ['nesteDato'], ['asc']);
    for (let i = 0; i < tiltakListe.length; i++) {
      const t = tiltakListe[i];
      if (t.royklopId === rId.toString()) {
        neste.push(t);
      } else {
        liste.push(t);
      }
    }
    if (neste.length > 0) {
      for (let x = 0; x < neste.length; x++) {
        const dd = neste[x];
        denne =
          denne +
          '<tr><td colSpan="3"><font color="#2e2d30" ><b>' + //purple
          makeDatoExtra(dd.nesteDato) +
          '  ' +
          dd.tiltakType +
          '</b></font></td></tr>';
      }
    }
    if (liste.length > 0) {
      andre =
        andre +
        '<tr><td colSpan="3">&nbsp;</td></tr><tr><td colSpan="3"><font color="#2e2d30" >Andre røykløp:</font></td></tr>'; //gray
      for (let y = 0; y < liste.length; y++) {
        const dd = liste[y];
        andre =
          andre +
          '<tr><td colSpan="3"><font color="#2e2d30" >' + //gray
          makeDatoExtra(dd.nesteDato) +
          '  ' +
          dd.tiltakType +
          '</font></td></tr>';
      }
    }
  }

  return (
    '<tr><td colSpan="3"><font color="#2e2d30" ><b>' + //purple
    type +
    '&nbsp;&nbsp; ' +
    plass +
    '</b></td></tr>' +
    denne +
    andre +
    '<tr><td colSpan="3">&nbsp;</td></tr>' +
    '<tr><td colSpan="3"><font color="#2e2d30" >Neste tiltak på enhet:</font></td></tr>' + //blue
    '<tr><td colSpan="3"><font color="#2e2d30" >' + //blue
    obj.datoTiltakEnhet +
    '</font></td></tr>'
  );
};

const getPlanlegtDato = (obj) => {
  if (obj.planlagtDato) {
    return (
      '<tr><td colSpan="3">Planlagt dato: <b>' +
      makeDatoExtra(obj.planlagtDato) +
      '</b></td><tr></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getPlanlagtFraTil = (obj) => {
  let f = '';
  let t = '';
  if (obj.besokFra) {
    f = '<td>Fra: ' + obj.besokFra + '</td>';
  }
  if (obj.besokTil) {
    t = '<td>Til: ' + obj.besokTil + '</td>';
  }
  if (f !== '' || t !== '') {
    return '<tr>' + f + t + '</tr>';
  } else {
    return '<tr></tr>';
  }
};

const getVarselStatus = (obj) => {
  if (obj.varselstatus) {
    return (
      '<tr><td>Varselstatus:&nbsp;</td><td><b>' +
      obj.varselstatus +
      '</b></td></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

const getTiltak = (obj) => {
  let s = '';
  if (obj.tiltakBruksenhet) {
    s = '<tr><td colSpan="3">' + obj.tiltakBruksenhet + '</td></tr>';
  }
  if (obj.tiltakRoyklop) {
    s = s + '<tr><td colSpan="3">' + obj.tiltakRoyklop + '</td></tr>';
  }
  if (s !== '') {
    s = '<tr><td colSpan="3"><b>Tiltak:</b></td></tr>' + s;
    return s;
  } else {
    return '<tr></tr>';
  }
};

const getGjennomfort = (obj) => {
  let status = '';
  var index1 = '';
  var utf = '';
  var til = '';
  if (obj.tiltakBrukenhetStatus) {
    index1 = obj.tiltakBrukenhetStatus.indexOf('/');
    utf =
      parseInt(obj.tiltakBrukenhetStatus.substring(0, index1)) +
      obj.antallLopPaaAlisteBesokt;
    til =
      parseInt(
        obj.tiltakBrukenhetStatus.substring(
          index1 + 1,
          obj.tiltakBrukenhetStatus.length
        )
      ) + obj.antallLopPaaAliste;
    status = utf + '/' + til;
    return '<tr><td>Gjennomført:&nbsp;</td><td>' + status + '</td></tr>';
  } else {
    return '<tr><td></td></tr>';
  }
};

const getEnhetsType = (obj) => {
  if (obj.bruksenhetType) {
    return '<tr><td>Type:&nbsp;</td><td>' + obj.bruksenhetType + '</td></tr>';
  } else {
    return '<tr></tr>';
  }
};

const getOpprettetDato = (obj) => {
  if (obj.dato) {
    return '<tr><td>Opprettet:&nbsp;</td><td>' + obj.dato + '</td></tr>';
  } else {
    return '<tr><td></td></tr>';
  }
};

const getBygningStatus = (obj) => {
  let bs = _.get(obj, 'bygningStatus');
  if (bs) {
    return (
      '<tr><td style="vertical-align:top">Bygn.status:&nbsp;</td><td>' +
      bs +
      '</td></tr>'
    );
  } else {
    return '<tr><td></td></tr>';
  }
};

const getGebyr = (obj) => {
  let avt = _.get(obj, 'avtaler');
  let gebyrType = _.get(obj, 'gebyrType');
  let farge = 'White';
  if (avt && gebyrType) {
    switch (gebyrType) {
      case 'G':
        farge = 'Green';
        break;
      case 'R':
        farge = 'Red';
        break;
      case 'O':
        farge = 'Orange';
        break;

      default:
        farge = 'White';
        break;
    }
    return (
      '<tr><td>&nbsp;</td></tr>' +
      '<tr style="border: 2px solid ' +
      farge +
      '"><td style="vertical-align:top">&nbsp;&nbsp;Avtale:&nbsp;</td><td>' +
      avt +
      '</td></tr>'
    );
  } else {
    return '<tr></tr>';
  }
};

export const getTooltipForLeggTilOgSoekEnheter = (obj, modus, parent) => {
  let neste =
    modus === 'Enheter'
      ? getNesteForEnhet(obj, parent)
      : getNesteForRoyklop(obj);
  let tt =
    '<div class="ttWordBreak" ><table><tbody> ' +
    getAdresse(obj) +
    getEiendom(obj) +
    neste +
    getRisiko(obj) +
    getRisikoFeiing(obj) +
    '</tbody></table>' +
    //  modus +
    '</div>';
  return tt;
};

const getTooltipForObjekterFagdata = (obj) => {
  let tt =
    '<div class="ttWordBreak" ><table><tbody> ' +
    getAdresse(obj) +
    getEiendomKommune(obj) +
    getEnhetsType(obj) +
    getOpprettetDato(obj) +
    getBygningStatus(obj) +
    getGebyr(obj) +
    '</tbody></table>' +
    '</div>';
  return tt;
};

export const getTooltipForMarkerClusterLayer = (obj, visning) => {
  //planlegging-enhet planlegging-arbeidsliste objekter-fagdata

  if (visning === 'objekter-fagdata') {
    return getTooltipForObjekterFagdata(obj);
  }
  let tt =
    '<div class="ttWordBreak" ><table style="width:100%"><tbody> ' +
    getAdresse(obj) +
    getEiendom(obj) +
    getPlanlegtDato(obj) +
    getPlanlagtFraTil(obj) +
    getVarselStatus(obj) +
    getTiltak(obj) +
    getGjennomfort(obj) +
    // visning +
    '</tbody></table>' +
    '</div>';
  return tt;
};

//objekter-fagdata

export const getTooltipForGjennomforing = (obj, modus) => {
  let tt =
    '<div class="ttWordBreak" ><table><tbody> ' +
    getAdresse(obj) +
    getEiendom(obj) +
    getPlanlegtDato(obj) +
    getPlanlagtFraTil(obj) +
    getVarselStatus(obj) +
    getTiltak(obj) +
    getGjennomfort(obj) +
/*     getRisiko(obj) +
    getRisikoFeiing(obj) + */
    '</tbody></table>' +
    '</div>';
  return tt;
};
