import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { PropTypes } from 'prop-types';
import { createPerson } from 'api/personApi';

class NyBruker extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: true,
      onSubmit: null,
      person: {
        id: 0,
        navn: '',
        brukernavn: null,
        adNavn: null,
        adOid: '',
        adKobling: false,
        telefon: '',
        mobil: '',
        epost: '',
        aktiv: false,
        organisasjonAdmin: false,
        pilotBruker: false
      }
    };
    this.onBrukerOpprettet = this.onBrukerOpprettet.bind(this);
  }

  onBrukerOpprettet(err, result) {
    if (!err) {
      this.props.onClose();
      this.props.oppdater(true);
    } else {
      alert(err.response);
    }
  }

  render() {
    let classNameButton =
      this.props.isEnabled === false
        ? 'btn btn-lg font16 btn-primary disabled'
        : 'btn btn-lg font16 btn-primary';

    return (
      <NKModal size={this.props.size} isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">{this.props.tittel}</h4>
          </div>
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="form">
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Aktiv</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              aktiv: !this.state.person.aktiv
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Navn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        autoFocus
                        maxLength="250"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              navn: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Tittel</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              tittel: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Telefonnummer</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              telefon: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Mobilnummer</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              mobil: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Epost</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        placeholder="Eks: ola.nordmann@gmail.com"
                        maxLength="60"
                        className="form-control"
                        type="email"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              epost: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Auth Token Oid</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              adOid: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Administrator</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              organisasjonAdmin: !this.state.person
                                .organisasjonAdmin
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Pilotbruker</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              pilotBruker: !this.state.person
                                .pilotBruker
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-4 form-group">
                      <label>Gjennomføring åpner standard kart først</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                    <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              visningKartStandard: !this.state.person.visningKartStandard
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className={classNameButton}
              onClick={() =>
                createPerson(this.state.person, this.onBrukerOpprettet)
              }>
              OK
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default NyBruker;
