import React from 'react';
import { getLoggprosessbyuser, getLoggprosessSiste200ForAlle, getLoggprosessForBruksenhet, putLoggprosessOppdaterNotification } from 'api/organisasjonApi.js';
import Spinner from 'components/generics/Spinner';
import ReactTable from '@norkart/react-table';
import ContextMenu from 'components/ContextMenu';
import { withRouter } from 'lib/enhancers';
import { getArbeidslisteById } from 'api/arbeidslisteApi.js';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import utropstegn_icon from 'images/48/utropstegn.png';
import { huskBreadcrumb } from 'components/Breadcrumbs.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Arbeidsliste'
        melding='Finner ikke arbeidsliste'
        onOk={onClose}
      />
    );
  },
};

class Prosesslogg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      item: null
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }
  
  componentDidMount() {
    if (this.props.type === 'alle') {
      getLoggprosessSiste200ForAlle(this.gotLogg);
    } else if (this.props.type === 'bruker') {
      getLoggprosessbyuser(this.gotLogg);
    } else {
      getLoggprosessForBruksenhet(this.props.brId, this.gotLogg);
    }
    
    //getLoggprosessAntallByUser(this.gotLogg2);
  }

  gotLogg = (err, res) => {
    this.setState({ isLoading: false, items: res });
    console.log(res);
  }

  gotLogg2 = (err, res) => {
    console.log(res);
  }

  tilSituasjon = (item) => {
    this.setState({ item: item });
    if (item.arbeidslisteId !== null) {
      getArbeidslisteById(item.arbeidslisteId, this.gotArbListe);
    }
  }

  gotArbListe = (err, res) => {
    if (res == null) {
      this.dialogs.setOpenDialog('OkDialog');
    } else {
      let al = res;
      huskBreadcrumb('gjennomforing_arbeidsliste_navn', al.navn);
      huskBreadcrumb('gjennomforing_arbeidsliste_adresse', this.state.item.bruksenhetAdresse);
      this.props.router.push({
        pathname: 'gjennomforing/' + al.id + '/' + al.navn + '/' + this.state.item.bruksenhetId,
        state: {
          adresse: this.state.item.bruksenhetAdresse
        }
      });
    }
  }

sett = (item) => {
  putLoggprosessOppdaterNotification(item.id, this.done);
}

done = (err, res) => {
  getLoggprosessbyuser(this.gotLogg);
}

  render() {
    if (this.state.isLoading === true) {
      return (
        <div>
        <Spinner className='h4' />
      </div>
      );
    }

    let columns = null;
    if (this.props.type === 'alle') {
      columns = this.getColumnsAlle();
    } else if (this.props.type === 'bruker') {
      columns = this.getColumns();
    } else {
      columns = this.getColumnsSituasjon();
    }
    

    return (
      <div>
        <h2>Systemmeldinger</h2>
        <p/>
        <ReactTable
          items={this.state.items}
          idProperty="id"
          showIndex={false}
          filterable={false}
          columns={columns}
          onRowClick={null}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  getColumns = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        
        formatter: function(item) {
          if (item.userNotification) {  
              return (
                <img
                  height='16'
                  width='16'
                  src={utropstegn_icon}
                  alt=''
                  title=''
                />
              );
          }
        },
      },
      {
        id: 'timeStampStart',
        name: 'Start tid',
        sortParams: 'timeStampStart',
        filterable: false,
        sortable: true,
        isSorted: false,
        formatter: function(item) {
          if (item.timeStampStart === null) {
            return '-';
          } else {
            var dato = new Date(item.timeStampStart);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            var time = dato.getHours().toString();
            var min = dato.getMinutes().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            if (time.length === 1) {
              time = '0' + time;
            }
            if (min.length === 1) {
              min = '0' + min;
            }
            return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
          }
        }
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'kilde',
        name: 'Kilde',
        sortParams: 'kilde',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'prosessStatusInfo',
        name: 'Feil',
        sortParams: 'prosessStatusInfo',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'exeption',
        name: 'Feilmelding',
        sortParams: 'exeption',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '10px' }}>{item.exeption}</span>;    
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if  (this.props.type != 'bruker') {
            return null;
          }
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              { this.props.type === 'bruker' && item.arbeidslisteId !== null ? 
                <ContextMenu.Item onClick={this.tilSituasjon.bind(this, item)}>
                Gå til situasjon ...
              </ContextMenu.Item> : null
              }
              {/* {item.userNotification ? 
              <ContextMenu.Item onClick={this.sett.bind(this, item)}>
              Marker som lest
            </ContextMenu.Item> : null
            } */}
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  };

  getColumnsSituasjon = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        
        formatter: function(item) {
          if (item.userNotification) {  
              return (
                <img
                  height='16'
                  width='16'
                  src={utropstegn_icon}
                  alt=''
                  title=''
                />
              );
          }
        },
      },
      {
        id: 'timeStampStart',
        name: 'Start tid',
        sortParams: 'timeStampStart',
        filterable: false,
        sortable: true,
        isSorted: false,
        formatter: function(item) {
          if (item.timeStampStart === null) {
            return '-';
          } else {
            var dato = new Date(item.timeStampStart);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            var time = dato.getHours().toString();
            var min = dato.getMinutes().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            if (time.length === 1) {
              time = '0' + time;
            }
            if (min.length === 1) {
              min = '0' + min;
            }
            return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
          }
        }
      },
      {
        id: 'personNavn',
        name: 'Bruker',
        sortParams: 'personNavn',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'kilde',
        name: 'Kilde',
        sortParams: 'kilde',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'prosessStatusInfo',
        name: 'Feil',
        sortParams: 'prosessStatusInfo',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'exeption',
        name: 'Feilmelding',
        sortParams: 'exeption',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '10px' }}>{item.exeption}</span>;    
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if  (this.props.type != 'bruker') {
            return null;
          }
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              { this.props.type === 'bruker' && item.arbeidslisteId !== null ? 
                <ContextMenu.Item onClick={this.tilSituasjon.bind(this, item)}>
                Gå til situasjon ...
              </ContextMenu.Item> : null
              }
              {item.userNotification ? 
              <ContextMenu.Item onClick={this.sett.bind(this, item)}>
              Marker som lest
            </ContextMenu.Item> : null
            }
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  };

  onKopier(e, item) {      
    e.stopPropagation();
    let s = item.tidspunkt + ' ';
    s = s + item.personNavn + ' ';
    s = s + item.organisasjonNavn + ' ';
    s = s + item.bruksenhetAdresse + ' ';
    s = s + 'Kilde: ' + item.kilde + ' ';
    s = s + 'Feil: ' + item.prosessStatusInfo + ' ';
    s = s + 'Feilmelding: ' + item.exeption;

    navigator.clipboard.writeText(s);
  }

  getColumnsAlle = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        
        formatter: function(item) {
          if (item.userNotification) {  
              return (
                <img
                  height='16'
                  width='16'
                  src={utropstegn_icon}
                  alt=''
                  title=''
                />
              );
          }
        },
      },
      {
        id: 'timeStampStart',
        name: 'Tidspunkt',
        sortParams: 'timeStampStart',
        filterable: false,
        sortable: true,
        isSorted: false,
        formatter: function(item) {
          if (item.timeStampStart === null) {
            return '-';
          } else {
            var dato = new Date(item.timeStampStart);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            var time = dato.getHours().toString();
            var min = dato.getMinutes().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            if (time.length === 1) {
              time = '0' + time;
            }
            if (min.length === 1) {
              min = '0' + min;
            }
            item.tidspunkt = dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
            return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
          }
        }
      },
      {
        id: 'personNavn',
        name: 'Bruker',
        sortParams: 'personNavn',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'organisasjonNavn',
        name: 'Organisasjon',
        sortParams: 'organisasjonNavn',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'kilde',
        name: 'Kilde',
        sortParams: 'kilde',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'prosessStatusInfo',
        name: 'Feil',
        sortParams: 'prosessStatusInfo',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'exeption',
        name: 'Feilmelding',
        sortParams: 'exeption',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '10px' }}>{item.exeption}</span>;    
        }
      },
      {
        id: 'kopi',
        name: 'Kopier',
        formatter: function(item) {
          return (
            <button onClick={(e) => this.onKopier(e, item)}>Kopier</button>
          );
        }.bind(this)
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if  (this.props.type != 'bruker') {
            return null;
          }
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              { this.props.type === 'bruker' && item.arbeidslisteId !== null ? 
                <ContextMenu.Item onClick={this.tilSituasjon.bind(this, item)}>
                Gå til sitiuasjon ...
              </ContextMenu.Item> : null
              }
              {item.userNotification ? 
              <ContextMenu.Item onClick={this.sett.bind(this, item)}>
              Marker som lest
            </ContextMenu.Item> : null
            }
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  };
}

export default withRouter(Prosesslogg);
