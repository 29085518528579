import React, { Component } from 'react';
import ReactModal from 'react-modal';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import SoekDialog from 'components/Dialogs/SoekDialog.jsx';
import DialogStore from 'lib/DialogStore';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
/* import SelectSearch, { fuzzySearch } from 'react-select-search'; */
import './autocom.css';
import PrikDialog from 'components/Dialogs/PrikDialog.js';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:testSide');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  SoekAvvikTypeDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <SoekDialog
      tittel={dialogData.tittel}
      liste={dialogData.liste}
      ident={dialogData.ident}
      tekst={dialogData.tekst}
      checkAktiv={dialogData.checkAktiv}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
      onValgt={owner.onValgt}
    />
  )
};



class Test3 extends Component {
  constructor(props) {
    super(props);
    this.state = { type: '', typer: [], avvikTyper: [], value: 'Eksempel..' } ;
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    oppslagsdataApi.getAvvikType(this.gotData);
  }

  gotData = (err, liste) => {
    debug('PUK', liste);
    this.setState({ avvikTyper: liste.avvikType });
    let typer = [];
    for (let i = 0; i < liste.avvikType.length; i++) {
      const element = liste.avvikType[i];
      let obj = { value: element.id, name: element.tekst.substring(0, 100) };
      typer.push(obj);
    }
    this.setState({ typer });
  };

  openEditor = () => {
    this.setState(
      { TestClass: 'testModal' },
      this.dialogs.setOpenDialog('TestDialog', {})
    );
  };

  setValue = (type) => {
    this.setState({ type });
  };

  openAvvikTyper = () => {
    this.dialogs.setOpenDialog('SoekAvvikTypeDialog',  {
      tittel: 'Søk avviktype',
      liste: this.state.avvikTyper,
      ident: 'id',
      tekst: 'tekst',
      checkAktiv: 'aktiv'
    });
  };

  onValgt = (row) => {
    debug('BRAM valgt', row);
    this.setState({ value: row.tekst });
    this.dialogs.closeDialog(); 
  };

  render() {
    if (this.state.typer.length < 1) {
      return <div style={{ padding: '30px' }}>Vent...</div>;
    }
    return (
      <div style={{ marginLeft: '10px' }}>
        <h2>Test 3</h2>
        <label>Test av ny autocomplete searchdialog (avvik-typer)</label>
        <br/>
        Tenkte å lage en search<b>DIALOG</b> som også kan takle større setninger.
        <br/>
        Her har resultat-felt også en mulighet for å vise 2 eller flere linjer.
        <br/>
        I dialogen kan man bruke 'tab' til å flytte focus. Så det kan også brukes uten å klikke med mus.
        <br />
        <br />
       {/*  <SelectSearch
          options={this.state.typer}
          value={this.state.type}
          onChange={this.setValue}
          search
          autoComplete={'on'}
          filterOptions={fuzzySearch}
          placeholder='Søk type..'
        />
        <br />
        <br />
        <label>{this.state.type}</label>

        <br />
        <br />

        <div className='prikprikprik1' style={{ width: '200px', fontSize: '14px' }}>Dette er et eksempel</div>
        <div className='prikprikprik2'>
          <button onClick={ this.openAvvikTyper} style={{ fontWeight: 'bold', fontSize: '16px' }} >...</button>
        </div>

        <br />
        <br /><br /> */}
        <PrikDialog width='500px' openSoekDialog={ this.openAvvikTyper} value={this.state.value}></PrikDialog>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Test3;
