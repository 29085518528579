import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import { getEksempelMaler, getAlleSmsEksempelMal } from 'api/malerApi';
import './maleditor.css';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { Image } from 'react-bootstrap';
import eksempel from 'images/Annet/eksempelmal.png';

const debug = debugFactory('nk:DokEditor');

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        tittel='Bruk eksempelmal tekst?'
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        onJa={owner.brukJa}
        onNei={onClose}
      />
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={'Kan ikke sette inn..'}
      melding={'Valgt mal har ingen tekst.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class EksempelDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maler: null,
      funksjonId: 0,
      funksjon: 'Ukjent',
      ventString: '',
      valgtmal: null,
      valgtInit: 0,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    if (this.props.mal.funksjon) {
      this.setState({ funksjon: this.props.mal.funksjon });
    }
    if (this.props.mal.funksjonId) {
      this.setState({ funksjonId: this.props.mal.funksjonId });
    }
    if (this.props.modus === 'Dokument'){
      getEksempelMaler(this.gotMaler);
    } else {
      getAlleSmsEksempelMal(this.gotSMSMaler)
    }
    
  };

  gotSMSMaler = (err, data) => {
    let maler = _.filter(data, { funksjonId: this.state.funksjonId });

    if (maler) {
      if (maler.length < 1) {
        this.setState({ maler: null });
        this.setState({
          ventString:
            'Ingen eksempelmaler for funksjon "' + this.state.funksjon + '"',
        });
      } else {
        this.setState({ maler });
        this.setState({ valgtmal: maler[0] });
        debug('EKSEMPEL', maler);
      }
    } else {
      this.setState({
        ventString:
          'Ingen eksempelmaler for funksjon "' + this.state.funksjon + '"',
      });
    }
  };

  gotMaler = (err, data) => {
    let maler = _.filter(data, { funksjonId: this.state.funksjonId });

    if (maler) {
      if (maler.length < 1) {
        this.setState({ maler: null });
        this.setState({
          ventString:
            'Ingen eksempelmaler for funksjon "' + this.state.funksjon + '"',
        });
      } else {
        this.setState({ maler });
        this.setState({ valgtmal: maler[0] });
        debug('EKSEMPEL', maler);
      }
    } else {
      this.setState({
        ventString:
          'Ingen eksempelmaler for funksjon "' + this.state.funksjon + '"',
      });
    }
  };

  brukTekst = (txt) => {
    if (this.state.valgtmal) {
      if (this.state.valgtmal.tekst) {
        this.dialogs.setOpenDialog('JaNeiDialog', {
          melding:
            'Ønsker du å erstatte din maltekst med valgt eksempelmaltekst?',
        });
      } else {
        this.dialogs.setOpenDialog('OkDialog');
      }
    }
  };

  brukJa = () => {
    this.props.onInsertEksempelMal(this.state.valgtmal.tekst);
  };

  velgMal = (valgtmal) => {
    this.setState({ valgtmal });
    let s = valgtmal.tekst;
  };

  render() {
    let background = { backgroundSize: 'cover' };

    return (
      <div
        style={{ height: '900px', width: '900px' }}
        className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg eksempelmal</h4>
        </div>
        <div className='modal-body'>
          <div style={{ marginBottom: '20px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <label style={{ marginLeft: '16px', marginBottom: '10px' }}>
                      Eksempelmal for funksjon {this.state.funksjon}:
                    </label>
                    <br />
                    <span
                      style={{
                        marginLeft: '16px',
                        fontSize: '14px',
                        color: 'blue',
                      }}>
                      Ved valg av en eksempelmal erstattes alle tekst med valgte
                      maltekst.
                    </span>
                  </td>
                  <td>
                    <button
                      style={{ marginRight: '20px', marginTop: '10px' }}
                      onClick={this.brukTekst}
                      className='pull-right btn btn-success'>
                      Sett inn eksempelmal
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table>
            <tbody>
              <tr>
                <td style={{ width: '150px', verticalAlign: 'top' }}>
                  {this.state.maler && (
                    <div
                      className='list-group'
                      style={{ maxHeight: '360px', overflow: 'auto' }}>
                      {this.state.maler.map(function(mal, inx) {
                        let klasse = { fontSize: '12px', marginBottom: '2px' };
                        if (this.state.valgtmal) {
                          if (mal.id === this.state.valgtmal.id) {
                            klasse = {
                              fontSize: '12px',
                              marginBottom: '2px',
                              border: '1px solid black',
                            };
                          }
                        }

                        var tittel = mal.beskrivelse;
                        var txt = mal.tekst;
                        if (txt) {
                          return (
                            <button
                              key={inx}
                              type='button'
                              style={klasse}
                              onClick={this.velgMal.bind(this, mal)}
                              className={'list-group-item '}>
                              {tittel}
                            </button>
                          );
                        } else {
                          return (
                            <button
                              style={{ fontSize: '12px' }}
                              key={inx}
                              type='button'
                              style={{ color: 'red' }}
                              title={'Eksempelmal uten tekst.'}
                              className='list-group-item'>
                              {tittel}
                            </button>
                          );
                        }
                      }, this)}
                    </div>
                  )}
                  {this.state.ventString !== '' && (
                    <div>
                      <span style={{ color: 'red', margin: '30px' }}>
                        {' '}
                        {this.state.ventString}
                      </span>
                    </div>
                  )}
                </td>
                <td
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingBottom: '10px',
                  }}>
                  {this.state.valgtmal && (
                    <div>
                      <span
                        style={{
                          padding: '20px',
                          border: '1px solid lightgray',
                          overflowY: 'scroll',
                          height: '730px',
                          position: 'absolute',
                        }}
                        /*  className='scaleEx' */
                        dangerouslySetInnerHTML={{
                          __html: this.state.valgtmal.tekst,
                        }}
                      />
                      <img
                        style={background}
                        responsive='true'
                        src={eksempel}
                        className='imgEx'
                      />
                    </div>
                  )}
                  {!this.state.valgtmal && (
                    <label style={{ margin: '60px' }}>Ingen tekst</label>
                  )}
                </td>
                <tr />
              </tr>
            </tbody>
          </table>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default EksempelDialog;
