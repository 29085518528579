import React, { Component } from 'react';
import withRouter from 'react-router/lib/withRouter';
import Link from 'react-router/lib/Link';
import styled from 'styled-components';
import { checkContextInnstillingerDatoInnlogging } from 'context/contextInnstillinger.js';
import { isFunction } from 'lodash';
import { debugFactory, objPickProps, objToHash } from 'utils';
import { now } from 'moment';

const debug = debugFactory('nk:components:breadcrumbs');

const CrumbsContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  & > * {
    display: inline-block;
  }
  & > * + *:before {
    content: ' > ';
    padding: 0 10px;
  }
`;

let addCrumbModifierRef; // Ref to BreadcrumbHelper instance
function brukBreadcrumbNameHelper(routeNameOrFindFn, routeNameOverrride) {
  if (routeNameOverrride) {
    addCrumbModifierRef(routeNameOrFindFn, routeNameOverrride);
  }
}
class Breadcrumbs extends Component {
  state = {
    forceRender: null,
  };

  constructor(props) {
    super(props);

    this.crumbsModifiers = {};

    // Make module-scope reference to the set of crumbmodifiers
    if (!addCrumbModifierRef || addCrumbModifierRef !== this.addCrumbModifier) {
      addCrumbModifierRef = (routeNameOrFindFn, routeNameOverrride) => {
        const findFn = isFunction(routeNameOrFindFn)
          ? routeNameOrFindFn
          : (route) => route.name === routeNameOrFindFn;

        const route = this.props.router.routes.find(findFn);
        this.addCrumbModifier(route, routeNameOverrride);
      };
    }

    // debug('constructor', { ...this });
  }

  setRouteHashes() {
    this.props.routes.forEach((route) => {
      /*
            All routes needs an id to connect the `crumbModifier` to.
            Since routes are added async through code-spiltting, 
            - Supposing that hashing as set of props on the `route` will yield the same hash every time.
            */
      if (!route.hash) {
        route.hash = objToHash(
          objPickProps(route, ['component', 'name', 'path'])
        );
      }
    });
  }

  addCrumbModifier(route, routeNameOverrride) {
    // debug('addCrumbModifier', this)
  /*   console.log('%c RIENI Crumble 1 ', 'font-size: 14px; background: firebrick; color: white',  route); 
    console.log('%c RIENI Crumble 2 ', 'font-size: 14px; background: tomato; color: white',  routeNameOverrride);  */
      
    if (!route) {
      console.log('%c RIENI Crumble Finner ikke route ', 'font-size: 14px; background: yellow; color: black',  routeNameOverrride); 
      return;
    }
    this.crumbsModifiers[route.hash] = routeNameOverrride;
    this.setState({ forceRender: now() });
  }

  getParsedCrumbs() {
    /*  **************************************************************F************************************ */
    checkContextInnstillingerDatoInnlogging();
    /*  ************************************************************************************************** */

    // Routes need a `path`
    const routes = this.props.routes.filter(({ path }) => path);
    const routeParams = this.props.params;

    const crumbs = routes
      .map((route, idx) => {
        const crumbName = this.crumbsModifiers[route.hash] || route.name;

        const crumbHref =
          '#/' +
          routes
            .slice(0, idx + 1)
            .reduce((acc, cur) => {
              // Some routes have multiple path-elements, so split
              const pathParts = cur.path.split('/');

              pathParts
                .filter((pathPart) => pathPart) // The root-path ("/") does not have parts when split by "/"
                .forEach((pathPart) => {
                  // If `pathPart` contains a variable, replace it with the corresponding value from `withRouter`-`routeParams`
                  const parsedPathPart = pathPart.replace(
                    /^\:(.+)/,
                    (match, $1) => {
                      // debug('match', { pathPart, match, $1 })
                      return routeParams[$1] || match;
                    }
                  );
                  acc.push(parsedPathPart);
                });
              return acc;
            }, [])
            .join('/');

        return { name: crumbName, href: crumbHref, routeHash: route.hash };
      })
      // Need a `name:
      .filter(({ name }) => name);

    // debug('parseCrumbs', { crumbs, modifiers: this.crumbsModifiers });

    return crumbs;
  }

  render() {
    //  debug('render', { ...this });

    this.setRouteHashes();

    const crumbs = this.getParsedCrumbs();

    return (
      <CrumbsContainer>
        {crumbs.map(({ name, href }, idx) => (
          <span key={`crumb-${idx}`}>
            {idx == crumbs.length - 1 ? (
              <span>{''}</span>
            ) : (
              <Link href={href}>{name}</Link>
            )}
          </span>
        ))}
      </CrumbsContainer>
    );
  }
}

export default withRouter(Breadcrumbs);

////////////// CONTEXT ///////////////////////
let cBreadcrumbs = {};

export const huskBreadcrumb = (key, value) => {
  cBreadcrumbs[key] = value;
  React.createContext(cBreadcrumbs);
  debug('BCR huskBreadcrumb', cBreadcrumbs);
};

export const brukBreadcrumb = (toReplace, key) => {
  brukBreadcrumbNameHelper(toReplace, cBreadcrumbs[key]);
};
