import React from 'react';
import { brukBreadcrumb } from 'components/Breadcrumbs.jsx';
import EnhetSituasjon from './EnhetSituasjon.jsx';
//import { getOrganisasjonRisikovurdering } from 'api/organisasjonApi';
import { getOrgRisiko } from 'context/contextInnstillinger.js';
import { getSituasjon } from 'context/contextGjennomforing.js';
import { debugFactory  } from 'utils';
import Spinner from 'components/generics/Spinner';

const debug = debugFactory('nk:gjennomforing:enhet:index');


const Enhet = class extends React.Component {
  state = {
    form: null,
    situasjon: null,
    risiko: false
  };

  componentDidMount() {  
    getSituasjon( 
      this.props.params.enhetId,
      this.props.params.arbeidslisteId,
      this.gotSituasjon);

    this.ammendBreadCrumbs();

    getOrgRisiko((res) => {
      this.setState({ risiko: res.data });
    });

      // Add event listener for F5 key press and refresh
      window.addEventListener('keydown', this.handleF5KeyPress);
    
  }

  componentWillUnmount() {
    // Remove event listener for F5 key press
    window.removeEventListener('keydown', this.handleF5KeyPress);
  }

  handleF5KeyPress = (event) => {
    if (event.key === 'F5') {
      event.preventDefault();
      window.history.back();
    }
  }



  gotSituasjon = (situasjon) => {
    
    this.setState({ situasjon });
  };

  componentDidUpdate(prevProps) {
    this.ammendBreadCrumbs();
  }

  ammendBreadCrumbs = () => {

    //============= REACT CONTEXT BREADCRUMBS  ====================
    brukBreadcrumb('Arbeidsliste', 'gjennomforing_arbeidsliste_navn');
    brukBreadcrumb('Enhet', 'gjennomforing_arbeidsliste_adresse');
  };

  closeForm = () => {
    this.setState({ form: null });
  };


  fetchSituasjonPaaNy = () => {
    this.setState({ situasjon: null });
    getSituasjon( 
      this.props.params.enhetId,
      this.props.params.arbeidslisteId,
      this.gotSituasjon);
  };
  refreshCache = () => {
//    this.props.clearCache();
  };
  render() {
    if (!this.state.situasjon) {
      return  <React.Fragment>
      <Spinner className='h4' />
    </React.Fragment>
    }
    if (!this.state.situasjon.adresse) {
      return  <React.Fragment>
      <br/><br/><br/><br/><br/><br/>
      <span
        style={{
          marginLeft: '160px',
          marginTop: '160px',
          color: 'blue',
          fontStyle: 'Italic',
        }}>
        Tar det lang tid? Tast F5
      </span>
      <Spinner className='h4' />
    </React.Fragment>
    }
    return (
      <div>
        <EnhetSituasjon
          fetchSituasjonPaaNy={this.fetchSituasjonPaaNy}
          refreshCache={this.refreshCache}
          situasjon={this.state.situasjon}
          arbListeId={this.props.params.arbeidslisteId}
          enhetId={this.props.params.enhetId}
          adresse={this.state.situasjon.adresse !== null ?  this.state.situasjon.adresse : this.props.location.state.adresse}
          eiendom={this.state.situasjon.eiendom }
          reellBruksenhet={this.state.situasjon.reellBruksenhet }
          matrikkelenhetId={this.state.situasjon.matrikkelenhetId }
          bruksenhetIdMa={this.state.situasjon.bruksenhetIdMa }
          fagbruksenhetId={this.state.situasjon.id }  //Pfff
          risiko={this.state.risiko}
          planlagtDato={this.state.situasjon.planlagtDato}
          base_path={
            '/gjennomforing/' +
            this.props.params.arbeidslisteId +
            '/' +
            this.props.params.arbeidslisteNavn
          }
          modal={false}
        />

        <p />
      </div>
    );
  }
};

export default Enhet;
