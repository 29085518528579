import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';
import fhv from 'images/Annet/forhandsvisning.png';


//Sett melding for rein tekst, eller children for html

class PreviewDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
  };

  print= () => {
    //
  };

  state = { isOpen: true };

  render() {
    return (
      <NKModal size='large' isOpen={this.props.isOpen} >
        <div className='modal-content' style={{ width: '790px' }}>
          <div className='modal-header'>
          
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>

         
            
            <h4 className='modal-title'>{this.props.tittel}</h4>
          </div>
        
          <div
            className='modal-body'
            style={{ marginLeft: '10px', marginRight: '10px' }}>
            <div ref={el => (this.componentRef = el)}
              style={{
                minWidth: '776px',
                maxWidth: '776px',
                minHeight: 'calc(100vh - 146px)',
                maxHeight: 'calc(100vh - 146px)', 
                paddingLeft: '29px',
                paddingRight: '44px',
                paddingTop: '26px',
                backgroundImage: 'url(' + fhv + ')',
                backgroundRepeat: 'repeat',
              
              /*   border: '1px solid gray', */
                 overflowY: 'auto', 
              }}>
              <span
                style={{
                  backgroundImage: 'url(' + fhv + ')',
                  backgroundRepeat: 'repeat',
                }}
                className='fr-view'
                dangerouslySetInnerHTML={{
                  __html: this.props.tekst,
                }}
              />
            </div>
         
          </div>
{/*           {this.props.onOk ? (
            <div className='modal-footer'>
              <button
                style={{
                  margin: '2px',
                  height: '48px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                className='btn btn-primary pull-right'
                onClick={this.props.onOk}>
                OK
              </button>
            </div>
          ) : (
            ''
          )} */}
         
        </div>

      </NKModal>
    );
  }
}

export default PreviewDialog;
