import React from 'react';
import Debug from 'debug';
import moment from 'moment';
import Enum from 'enum';
import CONFIG from 'lib/CONFIG';
import Ajv from 'ajv';
import 'array-flat-polyfill';
import ajvErrors from 'ajv-errors';
import {
  has,
  head,
  last,
  isString,
  isArray,
  groupBy,
  get as objGetDeep,
  set as objSetDeep,
} from 'lodash';
import { compose } from 'recompose';

// Relay for cumbersome, unintuitive or often-used utils
export { get as objGetDeep } from 'lodash';
export { default as slugify } from 'slugify';
export { default as jsonpath } from 'jsonpath';
export { default as objToHash } from 'object-hash';

// Relay all `./components`...
export * from './components.jsx';

export const assert = console.assert;

export const uuid = (a) => {
  // https://gist.github.com/jed/982883
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
};

export const callbackSpy = (callback, debug, label, ...args) => (...params) => {
  debug(`callbackSpy:${label || ''}`, args, params);
  callback && callback(...params);
};

export const debugFactory = (label) => Debug(label);

export const enumFactory = (enumList, options = {}) => {
  if (options.isFlaggable === false && Array.isArray(enumList)) {
    // Turn `enumList` into an object to make in non-flaggable (no bitwise operations and only one value)
    enumList = enumList.reduce((acc, cur, idx) => ({ ...acc, [cur]: idx }), {});
  }
  return new Enum(enumList, options);
};

export const enumsHasEnum = (available, selected, needle) => {
  // Test for `needle` among `selected` flags from `available`
  return selected === available.None
    ? false // 'None' is the only/exact value
    : needle
    ? selected.has(needle) // Is `needle` found?
    : selected !== available.None; // Is any value set?
};

export const objPickPropsDeep = (
  sourceObj,
  dottedPropsMap = {},
  { targetKeysPrefix } = {}
) => {
  if (isArray(dottedPropsMap)) {
    // Allows to pass just an array of props if the keys are the same on both sides of the map
    dottedPropsMap = dottedPropsMap.reduce(
      (acc, dottetKey) => ({ ...acc, [dottetKey]: dottetKey }),
      {}
    );
  }

  return Object.entries(dottedPropsMap).reduce(
    (acc, [targetKey, sourceKey]) => {
      if (targetKey === sourceKey) {
        targetKey = last(targetKey.split('.'));
      }
      if (targetKeysPrefix) {
        targetKey = targetKeysPrefix + targetKey;
      }
      objSetDeep(acc, targetKey, objGetDeep(sourceObj, sourceKey));
      return acc;
    },
    {}
  );
};

export const objPickProps = (obj, ...propList) => {
  propList = Array.isArray(head(propList)) ? head(propList) : propList; // Unpack if passed an array
  return propList.reduce((acc, cur) => {
    if (has(obj, cur)) {
      acc[cur] = objGetDeep(obj, cur);
    }
    return acc;
  }, {});
};

export const objOmitProps = (obj, ...propList) => {
  propList = Array.isArray(head(propList)) ? head(propList) : propList; // Unpack if passed an array
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!propList.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const objExtractProps = (obj, ...propList) => {
  // Modifies `obj`!!
  propList = Array.isArray(head(propList)) ? head(propList) : propList; // Unpack if passed an array
  const picked = objPickProps(obj, propList);
  propList.forEach((prop) => delete obj[prop]);
  return picked;
};

export const objEnsureDeep = (obj, dottetProp, defaultValToSet) => {
  // NOTE! Mutes `obj` with `defaultValToSet` on `dottetProp`-path if it does not exist
  const value = dottetProp.split('.').reduce((obj, prop, idx, { length }) => {
    if (!has(obj, prop)) {
      idx < length - 1 ? (obj[prop] = {}) : (obj[prop] = defaultValToSet);
    }
    return obj[prop];
  }, obj);

  return value;
};

export const tuplesToString = (...tuples) => {
  // Reduce each tuple by [bool, str] => bool ? str : '';
  if (!(tuples[0] instanceof Array)) {
    // Assume that the two first passed args make one tuple...
    tuples = [tuples];
  }
  const res = tuples
    .reduce((acc, [testVal, str]) => {
      if (testVal) {
        acc.push(str);
      }
      return acc;
    }, [])
    .join(' ');
  return res;
};

export const getBoundMethods = (instance, methods = {}) => {
  // Returns an object with all `methods` on `instance` bound to `instance`

  if (isArray(methods)) {
    // Allows to pass just an array of props if the keys are the same on both sides of the map
    methods = methods.reduce(
      (acc, method) => ({ ...acc, [method]: method }),
      {}
    );
  }

  return Object.entries(methods).reduce((acc, [key, method]) => {
    let boundHandler;

    try {
      boundHandler = (isString(method) ? instance[method] : method).bind(
        instance
      );
    } catch (err) {
      err.message = `getBoundMethods: '${method}': ${err.message}`;
      throw err;
    }
    acc[key] = boundHandler;
    return acc;
  }, {});
};

export const ensureSetStateCallback = (callback, ms = 0) => {
  // Some `setState` callbacks drop if component gets unmounted too early from parent...
  return () => setTimeout(callback, ms);
};

export const ajvFactory = (options = {}) =>
  ajvErrors(
    new Ajv({
      allErrors: true,
      jsonPointers: true,
      removeAdditional: true,
      ...options,
    })
  );

export const pivotSchemaErrors = (ajvErrorSchema) => {
  ajvErrorSchema = Array.isArray(ajvErrorSchema) ? ajvErrorSchema : [];
  // https://github.com/nguyen-bc/ajv-errors
  return ajvErrorSchema.reduce((m, e) => {
    var mp = null;
    var dp = '';
    var field = '';
    if (e.params && e.params.missingProperty) {
      mp = e.params.missingProperty;
    }

    if (e.dataPath && e.dataPath.length > 0) {
      dp = e.dataPath.slice(1);
    }

    if (dp !== '') {
      field = mp !== null ? `${dp}.${mp}` : dp;
    } else {
      field = mp;
    }

    return Object.assign(m, { [field]: e.message });
  }, {});
};

export const proxyFactory = (
  target = {},
  handler = {},
  { restrict = true } = {}
) => {
  const NOOP = (trap) => () => {
    throw new Error(`proxy trap ${trap} resticted`);
  };

  if (restrict) {
    // Only passed handler traps allowed!
    handler = Object.assign(
      {
        get: NOOP('get'),
        set: NOOP('set'),
        defineProperty: NOOP('defineProperty'),
        deleteProperty: NOOP('deleteProperty'),
        preventExtensions: NOOP('preventExtensions'),
        setPrototypeOf: NOOP('setPrototypeOf'),
      },
      handler
    );
  }

  return new Proxy(target, handler);
};

export const validatableMoment = (val, validFormat = 'DD.MM.YYYY') => {
  const date =
    val && isString(val) ? moment(val, validFormat, true) : moment(val);

  return date;
};

export const promisifyApiCall = (apiCall) => {
  // Assumes `callback` always is the last argument to the `apiCall`
  return (...args) => {
    const promise = new Promise((resolve, reject) => {
      // Push a 'resolve/reject-callback-wrapper' to end of `args` as `callback`
      args.push((err, res) => {
        return err ? reject(err) : resolve(res);
      });
      apiCall(...args);
    });
    return promise;
  };
};

export const promisifyCall = (call) => {
  // Assumes `callback` always is the last argument to the `apiCall`
  return (...args) => {
    const promise = new Promise((resolve) => {
      args.push((res) => {
        return resolve(res);
      });
      call(...args);
    });
    return promise;
  };
};

export const getDerivedChanges = (
  dottedPropsMap = {},
  nextProps = {},
  prevState = {},
  { nextPropsPrefix, debug } = {}
) => {
  // Use with `getDerivedStateFromProps` to get changes or `null`

  if (isArray(dottedPropsMap)) {
    // Allows to pass just an array of props if the keys are the same on both sides of the map
    dottedPropsMap = dottedPropsMap.reduce(
      (acc, dottetKey) => ({ ...acc, [dottetKey]: dottetKey }),
      {}
    );
  }

  const changes = Object.entries(dottedPropsMap).reduce(
    (acc, [nextPropsKey, prevStateKey]) => {
      if (nextPropsPrefix) {
        nextPropsKey = nextPropsPrefix + nextPropsKey;
      }

      const nextPropsVal = objGetDeep(nextProps, nextPropsKey);
      const prevStateVal = objGetDeep(prevState, prevStateKey);
      // ;(debug && debug('getDerivedChanges:change', {
      //     [nextPropsKey]: nextPropsVal,
      //     [prevStateKey]: prevStateVal,
      //     isChange: nextPropsVal !== prevStateVal}));
      if (nextPropsVal !== prevStateVal) {
        objSetDeep(acc, prevStateKey, nextPropsVal);
      }
      return acc;
    },
    {}
  );
  const hasChanges = Object.keys(changes).length ? changes : null;
  debug && debug('getDerivedChanges', { hasChanges });
  return hasChanges;
};

export const enhanceComponents = (components = {}, enhancements = []) => {
  return Object.entries(components).reduce(
    (acc, [name, Component]) => ({
      ...acc,
      [name]: compose(...enhancements)(Component),
    }),
    {}
  );
};

export const parseMultiLineString = (multiLineString) => {
  // Removes excess whitespace
  return multiLineString.replace(/\s{2,}/g, ' ').trim();
};

export const getDistinctsByKeys = (objectlist, ...keys) => {
  keys = keys.flatMap((key) => key);
/*   console.log(
    '********************** FLATMAP utils/index ************************************'
  ); */
  // TODO! Fixme! This is propably better solved with `lodash.uniqBy`...
  const distincts = Object.values(
    groupBy(objectlist, (o) => Object.values(objPickProps(o, keys)).join('-'))
  ).flatMap(head);
  return distincts;
};

export const constructorFactory = ({ constructor }, ...args) =>
  constructor(...args);

const goReplace = (str, find, repl) => {
  if (!str) {
    return;
  }
  str = str.replace(new RegExp(find, 'g'), repl);
  return str;
};

export const decodeNorskHtml = (t) => {
  t = goReplace(t, '&aring;', 'å');
  t = goReplace(t, '&Aring;', 'A');
  t = goReplace(t, '&aelig;', 'æ');
  t = goReplace(t, '&AElig;', 'Æ');
  t = goReplace(t, '&oslash;', 'ø');
  t = goReplace(t, '&Oslash;', 'Ø');
  t = goReplace(t, '&ndash;', '-');
  return t;
};

export const stripTekst = (tekst) => {
  tekst = goReplace(tekst, '<h2>', '');
  tekst = goReplace(tekst, '</h2>', '');
  tekst = goReplace(tekst, '<ul>', '');
  tekst = goReplace(tekst, '</ul>', '');
  tekst = goReplace(tekst, '<strong>', '');
  tekst = goReplace(tekst, '</strong>', '');
  tekst = goReplace(tekst, '<li>', ' -');
  tekst = goReplace(tekst, '</li>', '');
  tekst = goReplace(tekst, '<p>', '');
  tekst = goReplace(tekst, '</p>', '\n');
  tekst = goReplace(tekst, '<br>', '\n');
  tekst = goReplace(tekst, '<br/>', '\n');
  tekst = goReplace(tekst, '<br />', '');
  tekst = goReplace(tekst, '\n\n', '\n');
  tekst = goReplace(tekst, '&nbsp;', ' ');
  tekst = tekst.trim();
  return tekst;
};

export const getRandomString = (length) => {
  var randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

//For å kunne legge inn t script. I dette tilfelle 'html2pdf'
export const appendScript = (scriptToAppend) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

//Finn ut hvilken browser man bruker
export const getBrowser = () => {
  var browser = 'unknown';
  var ua = navigator.userAgent;
  if ((ua.indexOf('Opera') || ua.indexOf('OPR')) != -1) {
    browser = 'Opera';
  } else if (ua.indexOf('Edge') != -1) {
    browser = 'Edge';
  } else if (ua.indexOf('Edg') != -1) {
    browser = 'Edge';
  } else if (ua.indexOf('Chrome') != -1) {
    browser = 'Chrome';
  } else if (ua.indexOf('Safari') != -1) {
    browser = 'Safari';
  } else if (ua.indexOf('Firefox') != -1) {
    browser = 'Firefox';
  } else if (ua.indexOf('MSIE') != -1 || !!document.documentMode == true) {
    //IF IE > 10
    browser = 'IE';
  } else {
    browser = 'unknown';
  }
  return browser;
};

export const getKroner = (value) => {
  if (!value) {
    value = 0;
  }
  let kr = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: 'NOK',
    currencyDisplay: 'code',
  }).format(value);
  return kr;
};

export const getKronerDivRight = (value, max) => {
  if (!value) {
    value = 0;
  }
  let kr = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: 'NOK',
    currencyDisplay: 'code',
  }).format(value);
  if (max) {
    return (
      <div style={{ maxWidth: '160px' }}>
        <div className='pull-right'>{kr}&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
    );
  }
  return <div className='pull-right'>{kr}&nbsp;&nbsp;&nbsp;&nbsp;</div>;
};

export const stringBetweenPluss= (s1, s2, text) => {
  let pos = text.indexOf(s1) + s1.length;
  let s = text.substring(pos, text.indexOf(s2, pos));
  return s1 + s + s2;
};

export const ifReloadHome = (props, side) => {
  return;
 /*  const navigationEntries = window.performance.getEntriesByType('navigation');
  
  if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
    let path = '/';
    
    switch (side) {
      case 'gjennomforing':
        path = '/gjennomforing';
        break;
      case 'oppfolging':
        path = '/oppfolging/AvvikAapne';
        break;
      case 'planlegging':
        path = '/planlegging';
        break;
      default:
        path = '/';
        break;
    }
    
    props.router.push({ pathname: path });
  } */
};

export const isThisProd= () => {
  let env = CONFIG.REACT_APP_BUILD_DEFINITIONNAME;
  if ((env ==='BrannforebyggingClient Test') || (env === 'local')){
    return false;
  }
  return true;
};
