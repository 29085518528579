import React, { Component } from 'react';

import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:RedigerTekstbit');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Lagre'
      melding='Tekstbiter uten tekst kan ikke lagres.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class RedigerTekstbit extends Component {
  constructor(props) {
    super(props);
    this.state = { bit: { tekst: '*', tittel: '' } };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    debug('PLOKK redigerdialog', this.props);
    this.setState({ bit: this.props.bit });
  }

  lagre = () => {
    let bit = this.state.bit;
    if (bit.tekst) {
      if (bit.tekst === '') {
        this.dialogs.setOpenDialog('OkDialog');
        return;
      }
      this.props.lagreTekstbit(bit);
    }
  };

  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Endre tekstbit:  { this.state.bit.tittel}</h4>
        </div>
        <div className='modal-body'>
          <div className='form'>
            <div className='form-group' style={{ marginBottom: '6px' }}>
              <textarea
                style={{
                  padding: '6px',
                  marginLeft: '6px',
                  marginRight: '6px',
                  marginTop: '6px',
                  maxWidth: '98%',
                  width: '98%',
                  borderColor: 'lightGray',
                }}
                spellCheck='false'
                rows={16}
                resize='none'
                value={this.state.bit.tekst}
                onChange={(e) => {
                  this.setState({
                    bit: {
                      ...this.state.bit,
                      tekst: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary pull-right' onClick={this.lagre}>
            OK
          </button>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default RedigerTekstbit;
